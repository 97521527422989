<template>

  <form id="two-factor-authentication-form" name="two-factor-authentication-form" class="m-t" role="form" action="#" method="post" @submit="onCodeSubmit">

    <div class="form-group">
      <input type="text" class="form-control" :placeholder="$t('login.form.totp_placeholder')" required="" autocomplete="on" name="code" v-model="form.code">
    </div>
    <button type="submit" class="btn btn-primary block full-width m-b ladda-button" data-style="zoom-in">{{ $t('login.form.totpButton') }}</button>

  </form>
      

</template>

<style scoped>

</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';
import { updateApplicationDataForLoggedInUser } from '../../../utils/application';

@Component({
  components: {}
})
export default class RequestTotp extends Vue {

  form = {
    code : null
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=two-factor-authentication-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  onCodeSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/login-totp', this.form, options).then((response:any) => {
      
      if(response.isLoggedIn) {
        // We update the application data for logged in user
        var applicationDataForLoggedInUser = response.applicationDataForLoggedInUser;
        updateApplicationDataForLoggedInUser(this.$store, applicationDataForLoggedInUser);
        
        
      }
      if(response.redirect) {
        // We redirect to logged in page
        this.$router.push(response.redirect).catch(err => {});
      }
      else {
        this.laddaSubmit!.stop();
      }
      
    });
  }


}
</script>