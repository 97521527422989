import { Store } from '@fwk-node-modules/vuex';
import { authenticationTypes } from '@fwk-client/store/types';
import { types as applicationTypes } from '../store/application';
import { types as hospitalityTypes } from '../modules/hospitality/store';
import { types as amazonTypes } from '../modules/amazon/store';
import { types as eurofiscalisTypes } from '../store/eurofiscalis';

/**
 * updateApplicationDataForLoggedInUser
 * This method is called for logged in user to update application with data needed associated to the logged in user.
 * It should be called after user is logged in or when loading application with logged in user
 * @param store - the store instance.
 * @param applicationData - the computed application data generated by server.hooks.buildApplicationDataForLoggedInUser.
 *    - modules - the list of modules the user is allowed to access
 *    - user - the logged in user information
 *    - isLanguageSwitchAllowed - the possibility to switch language.
 *    - isConfigAllowed - the possibility to configurre the application
 *    - workerContract - the worker contract if available for the user company.
 */
export function updateApplicationDataForLoggedInUser (store:Store<any>, applicationData:any) {
    // We update the authentication
    store.commit('authentication/' + authenticationTypes.mutations.AUTH_SUCCESS, applicationData.user);
    // We update the possibility to switch language
    store.commit('application/' + applicationTypes.mutations.SET_IS_LANGUAGE_SWITCH_ALLOWED, applicationData.isLanguageSwitchAllowed);
    // We provide the list of  modules
    store.commit('application/' + applicationTypes.mutations.SET_AVAILABLE_MODULES, applicationData.modules);
    // We provide access to config
    store.commit('application/' + applicationTypes.mutations.SET_IS_CONFIG_ALLOWED, applicationData.isConfigAllowed);
    // We update the worker contract if any
    if(applicationData.workerContract) {
        store.commit('eurofiscalis/' + eurofiscalisTypes.mutations.WORKERS_SET_ACTIVE_CONTRACT, applicationData.workerContract);
    }
    // We update the shop if any
    if(applicationData.shops && applicationData.shops.length > 0) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SHOPS, applicationData.shops);
    }
    if(applicationData.selectedShop) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SELECTED_SHOP, applicationData.selectedShop);
    }
    // We update the site if any
    if(applicationData.sites && applicationData.sites.length > 0) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SITES, applicationData.sites);
    }
    if(applicationData.selectedSite) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SELECTED_SITE, applicationData.selectedSite);
    }
    // We update the shop content if any
    if(applicationData.shopContents && applicationData.shopContents.length > 0) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SHOP_CONTENTS, applicationData.shopContents);
    }
    if(applicationData.selectedShopContent) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SELECTED_SHOP_CONTENT, applicationData.selectedShopContent);
    }
    // We update the feed if any
    if(applicationData.feeds && applicationData.feeds.length > 0) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_FEEDS, applicationData.feeds);
    }
    if(applicationData.selectedFeed) {
        store.commit('application/' + applicationTypes.mutations.SET_USER_SELECTED_FEED, applicationData.selectedFeed);
    }
    // We update the agencies if any
    if(applicationData.agencies && applicationData.agencies.length > 0) {
        store.commit('hospitality/' + hospitalityTypes.mutations.SET_USER_AGENCIES, applicationData.agencies);
    }
    if(applicationData.selectedAgency) {
        store.commit('hospitality/' + hospitalityTypes.mutations.SET_USER_SELECTED_AGENCY, applicationData.selectedAgency);
    }
    // We update the amazon modules if any
    if(applicationData.amazonModules && applicationData.amazonModules.length > 0) {
        store.commit('amazon/' + amazonTypes.mutations.SET_USER_MODULES, applicationData.amazonModules);
    }
    if(applicationData.selectedAmazonModule) {
        store.commit('amazon/' + amazonTypes.mutations.SET_USER_SELECTED_MODULE, applicationData.selectedAmazonModule);
    }
    // We update the vat validation modules if any
    if(applicationData.vatValidationModules && applicationData.vatValidationModules.length > 0) {
        store.commit('vat-validation/' + amazonTypes.mutations.SET_USER_MODULES, applicationData.vatValidationModules);
    }
    if(applicationData.selectedVatValidationModule) {
        store.commit('vat-validation/' + amazonTypes.mutations.SET_USER_SELECTED_MODULE, applicationData.selectedVatValidationModule);
    }
}