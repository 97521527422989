<template>

    <div>
      <img v-if="authenticatorSrc != null" :src="authenticatorSrc" />
    </div>

</template>

<style scoped>

</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';
import { updateApplicationDataForLoggedInUser } from '../../../utils/application';

@Component({
  components: {}
})
export default class RequestTotp extends Vue {

  authenticatorSrc:string|null = null;

  form = {
    code : null
  }

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=two-factor-authentication-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  created() {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/auth/generate-totp', options).then((response:any) => {
      if(response.authenticatorSrc) {  
        this.authenticatorSrc = response.authenticatorSrc;
      }
    });
  }

  onCodeSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/login-totp', this.form, options).then((response:any) => {
      
      if(response.isLoggedIn) {
        // We update the application data for logged in user
        var applicationDataForLoggedInUser = response.applicationDataForLoggedInUser;
        updateApplicationDataForLoggedInUser(this.$store, applicationDataForLoggedInUser);
        
        
      }
      if(response.redirect) {
        // We redirect to logged in page
        this.$router.push(response.redirect).catch(err => {});
      }
      else {
        this.laddaSubmit!.stop();
      }
      
    });
  }


}
</script>