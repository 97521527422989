<template>
  <div id="login" class="middle-box text-center loginscreen animated fadeInDown">
    <div>

      <div class="logo">
        <img src="@public/img/igot-web/logo-hi.png" alt="Vigneron Online">
      </div>

      <h3 v-if="$te('login.sub-welcome') && $t('login.sub-welcome') != ''">{{ $t('login.welcome') }}</h3>
      <p v-if="$te('login.sub-title') && $t('login.sub-title') != ''">
        {{ $t('login.sub-title') }}
      </p>

      <LoginForm></LoginForm>

      <p class="m-t">
          <small>{{ $t("footer.copyright", [year]) }}</small>
      </p>

    </div>
  </div>

</template>

<style>
#login .logo img {
  max-width: 100%;
}
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import LoginForm from '../panels/authentication/Login.vue';

@Component({
  components: {
    LoginForm
  }
})
export default class Login extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();
  
}
</script>