<template>
  <div class="m-t">
    <template v-if="isLoginFromUsernameAllowed">

      <validation-observer v-slot="{ invalid }" slim>
        <form v-if="!isTwoFactorAuthenticationRequired" id="login-form" name="login-form"  role="form" action="#" method="post" @submit="onLoginFormSubmit" class="text-left">
        
          <validation-provider name="login" mode="eager" v-slot="{ errors, classes }" slim>
            <div :class="{'form-group':true, ...classes}">
              <input type="text" class="form-control" :placeholder="$t('profile.info.login_placeholder')" required="" autocomplete="on" name="login" v-model="loginForm.login">
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
           </validation-provider>
          <validation-provider name="password" mode="aggressive" v-slot="{ errors, classes }" slim>
            <div :class="{'form-group':true, ...classes}">
              <input type="password" class="form-control" :placeholder="$t('profile.info.password_placeholder')" required="" autocomplete="off" v-model="loginForm.password">
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </validation-provider>
          <div v-if="isRememberMeAllowed" class="form-group row">
            <label for="rememberMe" class="col-auto col-form-label">{{ $t('profile.info.rememberMe') }}</label>
            <div class="col">
              <p-check id="rememberMe" class="p-switch p-fill" color="success" v-model="loginForm.rememberMe">&nbsp;</p-check>
            </div>
          </div>
          <button :disabled="invalid" type="submit" class="btn btn-primary block full-width m-b ladda-button" data-style="zoom-in">{{ $t('profile.info.login-button') }}</button>

          <p v-if="isForgotPasswordAllowed" class="text-center">
            <a href="javascript:void(0)" @click="onResetPasswordClick">
                <small>{{ $t('profile.info.forgotPassword') }}</small>
            </a>
          </p>
          

          <b-modal size="lg" ref="resetPasswordModal" :title="$t('profile.reset-password.title')" hide-footer lazy>
            <ResetPassword v-on:password-reseted="onPasswordReseted" />
          </b-modal>

        </form>
      </validation-observer>
      <GenerateTotp v-if="isTwoFactorAuthenticationGenerated"></GenerateTotp>
      <RequestTotp v-if="isTwoFactorAuthenticationRequired"></RequestTotp>

    </template>

    <template v-if="isLoginFromSocial">

      <p class="text-center">
          <small>{{ $t('login.or') }}</small>
      </p>

      <div class="form-group">
        <a v-if="isLoginFromFacebookAllowed" :href="'/'+currentLanguage.code+'/api/auth/facebook/login'" class="btn btn-block btn-social btn-facebook">
          <span class="fa fa-facebook"></span> {{ $t('profile.social.sign-in-facebook') }}
        </a>
        <a v-if="isLoginFromGoogleAllowed" :href="'/'+currentLanguage.code+'/api/auth/google/login'" class="btn btn-block btn-social btn-google">
          <span class="fa fa-google"></span> {{ $t('profile.social.sign-in-google') }}
        </a>
      </div>
    
    </template>

    <template v-if="isRegisterAllowed">
      <p class="text-muted text-center">
          <small>{{ $t('login.register.message') }}</small>
      </p>
      <a class="btn btn-sm btn-white btn-block" href="register.html">{{ $t('login.register.link') }}</a>
    </template>
  </div>
</template>

<style>
  
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

import RequestTotp from './RequestTotp.vue';
import GenerateTotp from './GenerateTotp.vue';
import ResetPassword from './ResetPassword.vue';

import '@public/ext/inspinia-29/css/plugins/bootstrapSocial/bootstrap-social.css';
import * as Ladda from 'ladda';
import { updateApplicationDataForLoggedInUser } from '../../../utils/application';

@Component({
  components: {
    RequestTotp,
    GenerateTotp,
    ResetPassword
  }
})
export default class Login extends Vue {

  loginForm = {
    login : "",
    password : "",
    rememberMe : false
  }
  
  isLoginFromUsernameAllowed = true;
  isLoginFromFacebookAllowed = false;
  isLoginFromGoogleAllowed = false;
  isRegisterAllowed = false;
  isForgotPasswordAllowed = true;
  isRememberMeAllowed = true;
  
  isTwoFactorAuthenticationRequired = false;
  isTwoFactorAuthenticationGenerated = false;

  laddaSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var button:HTMLButtonElement|null = document.querySelector( 'form[name=login-form] button.ladda-button' );
    this.laddaSubmit = Ladda.create(button!);
  }

  get isLoginFromSocial() {
    return this.isLoginFromFacebookAllowed || this.isLoginFromGoogleAllowed;
  }

  onLoginFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      ...this.loginForm,
      rememberMe : this.loginForm.rememberMe && this.isRememberMeAllowed,
      redirect: this.$route.query.redirect
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/login', input, options).then((response:any) => {
      if(response.isTwoFactorAuthenticationRequired) {
        if(response.isTwoFactorAuthenticationGenerated) {
          this.isTwoFactorAuthenticationGenerated = true;
        }
        this.isTwoFactorAuthenticationRequired = true;
      }
      else if(response.isLoggedIn && response.applicationDataForLoggedInUser) {
        // We update the application data for logged in user
        var applicationDataForLoggedInUser = response.applicationDataForLoggedInUser;
        updateApplicationDataForLoggedInUser(this.$store, applicationDataForLoggedInUser);
        
        // We redirect to logged in page
        this.$router.push(response.redirect).catch(err => {});
      }
      else {
        this.laddaSubmit!.stop();
      }
    });
  }

  onResetPasswordClick() {
    // @ts-ignore
    this.$refs.resetPasswordModal.show()
  }

  onPasswordReseted() {
    // @ts-ignore
    this.$refs.resetPasswordModal.hide()
  }
  
}
</script>