<template>
  <validation-observer v-slot="{ invalid }" slim>  
    <form role="form" @submit="onFormSubmit" name="reset-password">
        <validation-provider name="email" mode="aggressive" v-slot="{ errors, classes }" slim>
          <div :class="{'form-group':true, 'row':true, ...classes}">
            <label for="resetPasswordEmail" class="col-lg-3 col-form-label">{{ $t('profile.info.email') }}</label>
            <div class="col-lg-9">
              <input id="resetPasswordEmail" type="email" :placeholder="$t('profile.info.email_placeholder')" class="form-control" v-model="form.email" required>
              <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
            </div>
          </div>
        </validation-provider>
        <button :disabled="invalid" class="btn btn-primary ladda-button reset-password" data-style="zoom-in" type="submit">{{ $t('profile.reset-password.button') }}</button>
    </form>
  </validation-observer>
</template>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import $ from 'jquery';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import * as Ladda from 'ladda';

@Component({
  components: { }
})
export default class ResetPassword extends Vue {

  form = {
    email: ''
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  created() {
    
  }

  mounted() {
    var resetPasswordButton:HTMLButtonElement|null = document.querySelector( 'form[name=reset-password] button.ladda-button.reset-password' );
    this.laddaSubmit = Ladda.create(resetPasswordButton!);
  }

  onFormSubmit(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "email" : this.form.email
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/auth/reset-password', input, options).then((response:any) => {
      if(response.reseted) {
        // We emit event as password is reseted
        this.$emit('password-reseted');
      }
      this.form.email = '';
      this.laddaSubmit!.stop();
    });
  }
  
}
</script>